import Link from 'next/link';
import {useRouter} from 'next/router';
import styles from './switcher.module.css';

const Switcher: React.FC = () => {
    const router = useRouter();

    return (
        <>
            {router.locales && router.locales.length > 1 && (
                <ul className={styles.list} data-testid="component-switcher">
                    {router.locales?.map(item => (
                        <li key={item} className={`${styles.item} ${router.locale === item ? styles.active : ''}`}>
                            <Link href="/" locale={item}>
                                <a href="/" className={styles.link}>
                                    {item}
                                </a>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default Switcher;

import React from 'react';
import send from '@/api/send';
import ContactFormEnum from '@/enums/ContactFormEnum';
import PrefixesEnum from '@/enums/PrefixesEnum';
import VariantsEnum from '@/enums/VariantsEnum';
import regex from '@/helpers/regex';
import {useLocaleState} from '@/store/locale';
import {useMessageState} from '@/store/message';
import Button from '@/UI/form-control/button';
import Form from '@/UI/form-control/form';
import Input from '@/UI/form-control/input';
import Textarea from '@/UI/form-control/textarea';
import Loader from '@/UI/loader';
import initialState, {IValues} from './initialState';
import styles from './contact.module.css';

const Contact: React.FC = () => {
    const [values, setValues] = React.useState<IValues>(initialState);
    const [isDisabled, setDisabled] = React.useState<boolean>(false);
    const [med, setMed] = React.useState<string>('');
    const [{locale}] = useLocaleState();
    const [, {setMessage}] = useMessageState();
    const isEmpty = (value: string) => value.trim().length === 0;
    const handleClear = () => {
        setValues(initialState);
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (med) {
            return;
        }

        setDisabled(true);

        let data = {};

        Object.keys(values).forEach(key => {
            if (values[key as keyof IValues].value) {
                data = {
                    ...data,
                    [key]: values[key as keyof IValues].value,
                };
            }
        });

        if (typeof window !== 'undefined') {
            data = {
                ...data,
                host: window.location.hostname,
            };
        }

        const response = await send(data);

        if (response.status) {
            const variant: {[key: string]: VariantsEnum} = {
                200: VariantsEnum.success,
                400: VariantsEnum.warning,
                405: VariantsEnum.danger,
            };

            setMessage({
                message: {
                    variant: variant[response.status],
                    content: locale.status[response.status],
                },
            });
        }

        setDisabled(false);
        handleClear();

        if (
            process.env.NODE_ENV === 'production'
            && process.env.NEXT_PUBLIC_ANALYTICS !== 'false'
            && response.status === '200'
            && window.gtag
        ) {
            window.gtag('event', 'conversion', {send_to: 'AW-879366540/bixDCIGHi7QBEIyjqKMD'});
        }
    };
    const handleBlur = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value, required} = target;
        const isRequired = () => (required ? isEmpty(value) : false);
        const hasError = () => (!isEmpty(value) ? !regex[values[name as keyof IValues].pattern].test(value) : false);

        if (values[name as keyof IValues].touched || values[name as keyof IValues].value) {
            setValues(inputs => ({
                ...inputs,
                [name]: {
                    ...inputs[name as keyof IValues],
                    value,
                    invalid: isRequired() || hasError(),
                    error: (isRequired() && locale.form.contact[name as ContactFormEnum].messages.empty)
                    || (hasError() && locale.form.contact[name as ContactFormEnum].messages.error),
                },
            }));
        }

        values[name as keyof IValues].touched = true;
    };
    const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = target;

        setValues(inputs => ({
            ...inputs,
            [name]: {
                ...inputs[name as keyof IValues],
                value,
            },
        }));
    };

    return (
        <div className={styles.wrapper}>
            {isDisabled && (<Loader className={styles.loader} data-testid="component-contact-loader" />)}
            <Form className={styles.form} data-testid="component-contact" onSubmit={handleSubmit}>
                <Input
                    required
                    id={`${PrefixesEnum.contactForm}_${ContactFormEnum.name}`}
                    name={ContactFormEnum.name}
                    label={locale.form.contact.name.label}
                    value={values.name.value}
                    disabled={isDisabled}
                    error={values.name.error}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <Input
                    id={`${PrefixesEnum.contactForm}_${ContactFormEnum.city}`}
                    name={ContactFormEnum.city}
                    label={locale.form.contact.city.label}
                    value={values.city.value}
                    disabled={isDisabled}
                    error={values.city.error}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <Input
                    id={`${PrefixesEnum.contactForm}_${ContactFormEnum.phone}`}
                    type="tel"
                    name={ContactFormEnum.phone}
                    label={locale.form.contact.phone.label}
                    value={values.phone.value}
                    disabled={isDisabled}
                    error={values.phone.error}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <Input
                    required
                    id={`${PrefixesEnum.contactForm}_${ContactFormEnum.email}`}
                    type="email"
                    name={ContactFormEnum.email}
                    label={locale.form.contact.email.label}
                    value={values.email.value}
                    disabled={isDisabled}
                    error={values.email.error}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <Input
                    id={`${PrefixesEnum.contactForm}_med`}
                    name="med"
                    label="Message"
                    value={med}
                    className={styles.med}
                    onChange={event => setMed(event.target.value)}
                />
                <Textarea
                    required
                    id={`${PrefixesEnum.contactForm}_${ContactFormEnum.message}`}
                    name={ContactFormEnum.message}
                    label={locale.form.contact.message.label}
                    value={values.message.value}
                    disabled={isDisabled}
                    error={values.message.error}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    variant={VariantsEnum.danger}
                    disabled={isDisabled}
                    className={styles.submit}
                >
                    {locale.submit}
                </Button>
            </Form>
        </div>
    );
};

export default Contact;

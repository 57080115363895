import {Variants} from 'framer-motion';
import AnimationsEnum from '@/enums/AnimationsEnum';

const subitem: Variants = {
    initial: {
        y: 40,
        opacity: 0,
    },
    enter: {
        y: 0,
        opacity: 1,
        transition: {
            ease: AnimationsEnum.ease,
            duration: AnimationsEnum.durationIn,
        },
    },
};

export {subitem};

import {m} from 'framer-motion';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React from 'react';
import pages from '@/data/pages';
import LocalesEnum from '@/enums/LocalesEnum';
import PagesEnum from '@/enums/PagesEnum';
import {useLocaleState} from '@/store/locale';
import Anchor from '@/UI/anchor';
import {animations} from '@/utils/animations';
import Menu from '../menu';
import styles from './nav.module.css';

type IProps = {
    className?: string
}

const Nav: React.FC<IProps> = ({className = ''}) => {
    const [isOpen, setOpen] = React.useState<boolean | undefined>(undefined);
    const router = useRouter();
    const [{locale}] = useLocaleState();
    const lang = router.locale as LocalesEnum || LocalesEnum.cs;
    const route = router.route.replace('/', '');
    const navigation = Object.keys(pages) as PagesEnum[];
    const handleClick = () => {
        setOpen(!isOpen);
    };

    return (
        <>
            <Menu isOpen={isOpen} className={styles.menu} onClick={handleClick} />
            <m.nav
                initial={false}
                animate={isOpen ? 'enter' : 'exit'}
                variants={animations.nav.list}
                className={`${styles.nav} ${isOpen ? styles.open : ''} ${className}`}
                data-testid="component-nav"
            >
                <ul className={styles.list}>
                    {navigation.length > 0 && navigation
                        .filter(item => item !== PagesEnum.purifier)
                        .map(key => (
                            <m.li
                                key={pages[key].id}
                                variants={animations.nav.item}
                                className={styles.item}
                            >
                                <Link href={`/${pages[key].routes[lang].route}`} prefetch={false}>
                                    <Anchor
                                        href={`/${pages[key].routes[lang].route}`}
                                        className={`${styles.link} ${route.includes(pages[key].routes[lang].route) ? styles.active : ''}`}
                                        onClick={handleClick}
                                    >
                                        {locale.pages[key].label.toUpperCase()}
                                    </Anchor>
                                </Link>
                            </m.li>
                        ))}
                </ul>
            </m.nav>
        </>
    );
};

export default Nav;

import {
    LazyMotion,
    domAnimation,
    m
} from 'framer-motion';
import {useRouter} from 'next/router';
import React from 'react';
import pages from '@/data/pages';
import LocalesEnum from '@/enums/LocalesEnum';
import PagesEnum from '@/enums/PagesEnum';
import {useLocaleState} from '@/store/locale';
import {useMessageState} from '@/store/message';
import Alert from '@/UI/alert';
import {animations} from '@/utils/animations';
import Footer from './footer';
import Header from './header';
import Meta from './meta';
import NoScript from './noscript';
import Section from './section';
import styles from './layout.module.css';

type IProps = {
    label?: string
    className?: string
    isHomepage?: boolean
    hasHeader?: boolean
    hasFooter?: boolean
    children: React.ReactNode
}

const Layout: React.FC<IProps> = ({
    label = '',
    className = '',
    isHomepage = false,
    hasHeader = true,
    hasFooter = false,
    children,
}) => {
    const router = useRouter();
    const [{locale}] = useLocaleState();
    const [{message}] = useMessageState();
    const lang = router.locale as LocalesEnum || LocalesEnum.cs;
    const route = router.route.replace('/', '');
    const list = Object.keys(pages) as PagesEnum[];
    const id = list.find(key => pages[key].routes[lang].route === route) as PagesEnum;
    const {title, description} = locale.pages[id] || {title: 'Tomton', description: ''};

    return (
        <>
            <Meta title={title} description={description} />
            <NoScript locale={locale} />
            <main className={`${styles.main} ${className}`} data-testid="component-layout">
                <LazyMotion strict features={domAnimation}>
                    {hasHeader && (
                        <Header />
                    )}

                    <Section isHomepage={isHomepage} className={isHomepage ? styles.section : ''}>
                        {label && (
                            <m.h1
                                initial="initial"
                                animate="enter"
                                variants={animations.h1}
                            >
                                {label}
                            </m.h1>

                        )}
                        {children}
                    </Section>

                    {hasFooter && (
                        <Footer />
                    )}
                </LazyMotion>
            </main>

            {!!message.content && (
                <Alert isOpen variant={message.variant} timeout={4}>
                    {message.content}
                </Alert>
            )}
        </>
    );
};

export default Layout;

import {Variants} from 'framer-motion';
import AnimationsEnum from '@/enums/AnimationsEnum';

const fadeIn: Variants = {
    initial: {opacity: 0},
    enter: {
        opacity: 1,
        transition: {
            duration: AnimationsEnum.durationIn,
            delay: 0.2,
        },
    },
};

export {fadeIn};

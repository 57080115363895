import React from 'react';
import VariantsEnum from '@/enums/VariantsEnum';
import styles from './button.module.css';

type IOnlyIcon = {hasOnlyIcon?: false; title?: string} | {hasOnlyIcon?: true; title: string};

type IButton = {
    type?: 'button' | 'submit'
    disabled?: boolean
    variant?: VariantsEnum
    tabindex?: number
    className?: string
    isGrouped?: boolean
    children: React.ReactNode
    onClick?: (e: React.MouseEvent) => void,
}

type IProps = IOnlyIcon & IButton;

const Button = React.forwardRef<HTMLButtonElement, IProps>(({
    type = 'button',
    disabled = false,
    variant,
    title = undefined,
    tabindex,
    className = '',
    isGrouped = false,
    hasOnlyIcon = false,
    children,
    onClick = undefined,
}, ref) => (
    <button
        ref={ref}
        disabled={disabled}
        type={type}
        tabIndex={tabindex}
        className={`${styles.button} ${variant ? styles[variant] : ''} ${isGrouped ? styles.isGrouped : ''} ${hasOnlyIcon ? styles.hasOnlyIcon : ''} ${className}`}
        aria-label={title}
        data-testid="component-button"
        onClick={onClick}
    >
        {children}
    </button>
));

Button.displayName = 'Button';

export default Button;

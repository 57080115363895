import {ILocale} from '@/data/locales';
import styles from './noscript.module.css';

type IProps = {
    locale?: ILocale
}

const NoScript: React.FC<IProps> = ({locale}) => (
    <noscript data-testid="component-noscript" className={styles.noscript}>
        <strong>
            {locale?.noscript.title}
        </strong>
        <p>
            {locale?.noscript.description.begin}<br />
            <a href={locale?.noscript.url} target="_blank" rel="noreferrer noopener">{locale?.noscript.description.here}</a> {locale?.noscript.description.end}
        </p>
    </noscript>
);

export default NoScript;

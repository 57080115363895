import LocalesEnum from '@/enums/LocalesEnum';
import PagesEnum from '@/enums/PagesEnum';
import PrefixesEnum from '@/enums/PrefixesEnum';

type IPages = {
    [key in PagesEnum]: {
        id: string;
        routes: {
            [locale in LocalesEnum]: {
                route: string;
            };
        };
    };
};

const prefix = PrefixesEnum.pages;
const pages: IPages = {
    [PagesEnum.products]: {
        id: `${prefix}_p2`,
        routes: {
            [LocalesEnum.cs]: {route: 'produkty'},
            [LocalesEnum.en]: {route: 'products'},
            [LocalesEnum.pl]: {route: 'produkty'},
            [LocalesEnum.hu]: {route: 'termekek'},
        },
    },
    [PagesEnum.purifier]: {
        id: `${prefix}_p3`,
        routes: {
            [LocalesEnum.cs]: {route: 'cisticka-vzduchu'},
            [LocalesEnum.en]: {route: 'air-purifier'},
            [LocalesEnum.pl]: {route: 'oczyszczacz-powietrza'},
            [LocalesEnum.hu]: {route: 'legtisztito'},
        },
    },
    [PagesEnum.gallery]: {
        id: `${prefix}_p4`,
        routes: {
            [LocalesEnum.cs]: {route: 'galerie'},
            [LocalesEnum.en]: {route: 'gallery'},
            [LocalesEnum.pl]: {route: 'galeria'},
            [LocalesEnum.hu]: {route: 'kepgaleria'},
        },
    },
    [PagesEnum.video]: {
        id: `${prefix}_p5`,
        routes: {
            [LocalesEnum.cs]: {route: 'videa'},
            [LocalesEnum.en]: {route: 'videos'},
            [LocalesEnum.pl]: {route: 'filmy'},
            [LocalesEnum.hu]: {route: 'videok'},
        },
    },
};

export default pages;

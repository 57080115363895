import {domAnimation} from 'framer-motion';
import {fadeIn} from './fadeIn';
import {h1} from './h1';
import {homepage} from './homepage';
import {item} from './item';
import {nav} from './nav';
import {section} from './section';
import {subitem} from './subitem';
import {wrapper} from './wrapper';

const animations = {
    h1,
    section,
    wrapper,
    item,
    fadeIn,
    homepage,
    subitem,
    nav,
};

export {animations};

export default domAnimation;

import {Variants} from 'framer-motion';
import AnimationsEnum from '@/enums/AnimationsEnum';

const homepage: Variants = {
    initial: {opacity: 0},
    enter: {
        opacity: 1,
        transition: {
            ease: AnimationsEnum.ease,
            duration: 0.6,
        },
    },
};

export {homepage};

import {headers} from './utils/options';

export type IData = {
    [key: string]: string
}

export type IResponse = {
    status: string
}

const options = {
    post: (data: IData): RequestInit => ({
        method: 'POST',
        headers,
        body: JSON.stringify(data),
    }),
};
const send = (data: IData): Promise<IResponse> => fetch('/api/send', options.post(data))
    .then(response => response.json());

export default send;

import Image from 'next/image';
import {useRouter} from 'next/router';
import cs from '@/data/locales/cs';
import LocalesEnum from '@/enums/LocalesEnum';
import {useLocaleState} from '@/store/locale';
import styles from './address.module.css';

const Address: React.FC = () => {
    const router = useRouter();
    const [{locale}] = useLocaleState();
    const handleClick = () => {
        if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ANALYTICS !== 'false' && window.gtag) {
            window.gtag('event', 'Click', {event_category: 'facebook'});
        }
    };

    return (
        <>
            <address className={styles.address} data-testid="component-address">
                <strong>
                    {locale.address.title}
                </strong>
                <br />
                {locale.address.street}
                <br />
                {locale.address.city}
                <br />

                {locale.address.country && (
                    <>
                        {locale.address.country}
                        <br />
                    </>
                )}

                {(router.locale === LocalesEnum.cs || router.locale === LocalesEnum.en) && (
                    <>
                        <br />
                        E-mail: <a href="mailto:info@tomton.cz">info@tomton.cz</a>
                        <br />
                        {locale.address.phone}: <a href="tel:42073447721">+420 734 447 721</a>
                        <br />
                        ({locale.address.opening})
                        <br />
                    </>
                )}

                <br />
                {locale.address.crn}: {locale.address.crnNumber}
                <br />
                {locale.address.vat}: {locale.address.vatNumber}
            </address>

            {router.locale === LocalesEnum.pl && (
                <div className={styles.socials}>
                    <a
                        href="https://www.facebook.com/TomtonOffice/"
                        className={styles.social}
                        target="_black"
                        rel="noreferrer noopener"
                        aria-label="facebook"
                        onClick={handleClick}
                    >
                        <Image
                            src="/icons/facebook.svg"
                            alt=""
                            width={48}
                            height={48}
                        />
                    </a>
                </div>
            )}

            {router.locale === LocalesEnum.pl && (
                <address className={styles.address}>
                    <strong>
                        {cs.address.title}
                    </strong>
                    <br />
                    {cs.address.street}
                    <br />
                    {cs.address.city}
                    <br />
                    <br />
                    {locale.address.crn}: {cs.address.crnNumber}
                    <br />
                    {locale.address.vat}: {cs.address.vatNumber}
                </address>
            )}

            {locale.address.residence && (
                <>
                    {locale.address.residence.map(residence => (
                        <address key={residence.title} className={styles.address}>
                            <strong>
                                {residence.title}
                            </strong>
                            {residence.subtitle && (
                                <>
                                    <br />
                                    <span>
                                        {residence.subtitle}
                                    </span>
                                </>
                            )}
                            <br />
                            {residence.street}
                            <br />
                            {residence.city}
                        </address>
                    ))}
                </>
            )}

            <div className={styles.socials}>
                <a
                    href="https://www.facebook.com/tomtonradiators/"
                    className={styles.social}
                    target="_black"
                    rel="noreferrer noopener"
                    aria-label="facebook"
                    onClick={handleClick}
                >
                    <Image
                        src="/icons/facebook.svg"
                        alt=""
                        width={48}
                        height={48}
                    />
                </a>
                <a
                    href="https://www.instagram.com/tomton.multifunctionalheating/"
                    className={styles.social}
                    target="_black"
                    rel="noreferrer noopener"
                    aria-label="instagram"
                >
                    <Image
                        src="/icons/instagram.svg"
                        alt=""
                        width={48}
                        height={48}
                    />
                </a>
                <a
                    href="https://www.youtube.com/channel/UCPucxb4oNuEyDRIv5yHlGug"
                    className={styles.social}
                    target="_black"
                    rel="noreferrer noopener"
                    aria-label="you tube"
                >
                    <Image
                        src="/icons/youtube.svg"
                        alt=""
                        width={48}
                        height={48}
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/tomton/mycompany/?viewAsMember=true"
                    className={styles.social}
                    target="_black"
                    rel="noreferrer noopener"
                    aria-label="linkedin"
                >
                    <Image
                        src="/icons/linkedin.svg"
                        alt=""
                        width={48}
                        height={48}
                    />
                </a>
            </div>
            <p className={styles.description}>
                {locale.address.description}
            </p>
        </>
    );
};

export default Address;

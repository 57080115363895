import React from 'react';
import {useLocaleState} from '@/store/locale';
import {animationIn, animationOut} from './menu.animations';
import styles from './menu.module.css';

type IProps = {
    isOpen: boolean | undefined
    className?: string
    onClick: (isOpen: boolean) => void
}

const Menu: React.FC<IProps> = ({
    isOpen = undefined,
    className = '',
    onClick,
}) => {
    const [{locale}] = useLocaleState();
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);
    const pathTopRef = React.useRef<SVGPathElement | null>(null);
    const pathMiddleRef = React.useRef<SVGPathElement | null>(null);
    const pathBottomRef = React.useRef<SVGPathElement | null>(null);
    const handleTriggerAnimationIn = () => {
        animationIn({
            pathTop: pathTopRef.current,
            pathMiddle: pathMiddleRef.current,
            pathBottom: pathBottomRef.current,
        });
    };
    const handleTriggerAnimationOut = () => {
        animationOut({
            pathTop: pathTopRef.current,
            pathMiddle: pathMiddleRef.current,
            pathBottom: pathBottomRef.current,
        });
    };
    const handleClick = () => {
        onClick(!isOpen);
    };

    React.useEffect(() => {
        if (isOpen === undefined) {
            return;
        }

        (isOpen ? handleTriggerAnimationIn : handleTriggerAnimationOut)();
    }, [isOpen]);

    return (
        <button
            ref={buttonRef}
            type="button"
            className={`${styles.button} ${className}`}
            data-testid="component-menu"
            aria-label={isOpen ? locale.menu.close : locale.menu.open}
            onClick={handleClick}
        >
            <svg className={styles.icon} viewBox="0 0 100 100">
                <path ref={pathTopRef} d="M30,40 L70,40 Z" />
                <path ref={pathMiddleRef} d="M30,50 L70,50 Z" />
                <path ref={pathBottomRef} d="M30,60 L70,60 Z" />
            </svg>
        </button>
    );
};

export default Menu;

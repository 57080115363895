/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import Script from 'next/script';

type IProps = {
    title?: string
    description?: string
}

const Meta: React.FC<IProps> = ({title = '', description = ''}) => (
    <>
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="preload" as="font" href="/fonts/helvetica.woff2" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" as="font" href="/fonts/helvetica-bold.woff2" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" as="font" href="/fonts/avenir-ultralight.woff2" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" as="font" href="/fonts/avenir-bold.woff2" type="font/woff2" crossOrigin="anonymous" />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={description} />
            <meta property="og:url" content="" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:site" content="" />
            <link rel="icon" href="/favicons/favicon.png" type="image/png" />
            <link rel="apple-touch-icon" href="/favicons/apple-touch-icon.png" sizes="180x180" />
            <link rel="icon" type="image/png" href="/favicons/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="/favicons/favicon-16x16.png" sizes="16x16" />
            <link rel="icon" type="image/png" href="/favicons/android-chrome-192x192.png" sizes="192x192" />
            <link rel="icon" type="image/png" href="/favicons/android-chrome-256x256.png" sizes="256x256" />
            <link rel="manifest" href="/favicons/site.webmanifest" />
            <meta name="apple-mobile-web-app-title" content="Tomton" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black" />
            <meta name="application-name" content="Tomton" />
            {process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ANALYTICS !== 'false' && (
                <noscript>
                    <img height="1" width="1" style={{display: 'none'}} src="https://www.facebook.com/tr?id=596458737611285&amp;ev=PageView&amp;noscript=1" alt="" />
                </noscript>
            )}
        </Head>
        {process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ANALYTICS !== 'false' && (
            <>
                <Script
                    id="tagManager79"
                    strategy="lazyOnload"
                    src="https://www.googletagmanager.com/gtag/js?id=UA-79781108-1"
                    crossOrigin="anonymous"
                />
                <Script id="dataLayer79" strategy="lazyOnload" >
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag() {dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'UA-79781108-1');`}
                </Script>
                <Script
                    id="tagManaager87"
                    strategy="lazyOnload"
                    src="https://www.googletagmanager.com/gtag/js?id=AW-879366540"
                    crossOrigin="anonymous"
                />
                <Script id="dataLayer87" strategy="lazyOnload">
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag() {dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'AW-879366540');`}
                </Script>
                <Script id="fb" strategy="lazyOnload">
                    {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '596458737611285');
                    fbq('track', 'PageView');`}
                </Script>
                <Script id="hotjar" strategy="lazyOnload">
                    {`(function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:2627155,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
                </Script>
                <Script id="leady" strategy="lazyOnload">
                    {`var leady_track_key="l6XyN9szPo51A9lh";
                    (function(){
                        var l=document.createElement("script");l.type="text/javascript";l.async=true;
                        l.src='https://t.leady.com/'+leady_track_key+"/L.js";
                        var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(l,s);
                    })();`}
                </Script>
                <Script id="seznam" strategy="lazyOnload">
                    {`/* <![CDATA[ */
                        var seznam_retargeting_id = 37453;
                    /* ]]> */`}
                </Script>
                <Script
                    id="retargeting"
                    strategy="lazyOnload"
                    src="https://c.imedia.cz/js/retargeting.js"
                    crossOrigin="anonymous"
                />
            </>
        )}
    </>
);

export default Meta;

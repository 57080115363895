import ContactFormEnum from '@/enums/ContactFormEnum';
import {RegexEnum} from '@/helpers/regex';

export type IValue = {
    value: string
    touched: boolean
    invalid: boolean
    pattern: RegexEnum,
    error: string
}

export type IValues = {
    [key in ContactFormEnum]: IValue
}

const initialState: IValues = {
    [ContactFormEnum.name]: {
        value: '',
        touched: false,
        invalid: false,
        pattern: RegexEnum.name,
        error: '',
    },
    [ContactFormEnum.city]: {
        value: '',
        touched: false,
        invalid: false,
        pattern: RegexEnum.text,
        error: '',
    },
    [ContactFormEnum.phone]: {
        value: '',
        touched: false,
        invalid: false,
        pattern: RegexEnum.phone,
        error: '',
    },
    [ContactFormEnum.email]: {
        value: '',
        touched: false,
        invalid: false,
        pattern: RegexEnum.email,
        error: '',
    },
    [ContactFormEnum.message]: {
        value: '',
        touched: false,
        invalid: false,
        pattern: RegexEnum.text,
        error: '',
    },
};

export default initialState;

import {Variants} from 'framer-motion';

type IVariants = {
    list: Variants
    item: Variants
}

const nav: IVariants = {
    list: {
        enter: {
            opacity: 1,
            visibility: 'visible',
            transition: {
                ease: 'easeOut',
                duration: 0.4,
                delayChildren: 0.1,
                staggerChildren: 0.1,
                when: 'beforeChildren',
            },
        },
        exit: {
            opacity: 0,
            transition: {
                ease: 'easeOut',
                duration: 0.4,
                staggerChildren: 0.1,
                when: 'afterChildren',
            },
            transitionEnd: {visibility: 'hidden'},
        },
    },
    item: {
        enter: {
            y: 0,
            opacity: 1,
            transition: {
                ease: 'easeOut',
                duration: 0.4,
            },
        },
        exit: {
            y: '-6vh',
            opacity: 0,
            transition: {
                ease: 'easeOut',
                duration: 0.2,
            },
        },
    },
};

export {nav};

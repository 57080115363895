import Link from 'next/link';
import Logo from '@/assets/images/logo.svg';
import {useLocaleState} from '@/store/locale';
import Nav from '../../nav';
import Switcher from '../../switcher';
import styles from './header.module.css';

const Header: React.FC = () => {
    const [{locale}] = useLocaleState();

    return (
        <header className={styles.header} data-testid="component-header">
            <div className={`${styles.content} container`}>
                <Link href="/">
                    <a href="/" className={styles.link}>
                        <Logo className={styles.logo} />
                        <span className="sr-only">{locale.logo}</span>
                        <span className={styles.title}>Make your space.</span>
                    </a>
                </Link>
                <Nav />
                <Switcher />
            </div>
        </header>
    );
};

export default Header;

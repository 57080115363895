import {useLocaleState} from '@/store/locale';
import Anchor from '@/UI/anchor';
import Contact from '../../form/contact';
import Address from './address';
import styles from './footer.module.css';

const Footer: React.FC = () => {
    const [{locale}] = useLocaleState();

    return (
        <footer className={styles.footer} data-testid="component-footer">
            <div className="container">
                <span className={styles.heading}>
                    {locale.footer.heading}
                </span>
                <section className={styles.section}>
                    <div className={styles.subsection}>
                        <Address />
                    </div>
                    <div className={styles.subsection}>
                        <Contact />
                    </div>
                </section>
                <section className={styles.section}>
                    <div className={styles.terms}>
                        <ul className={styles.list}>
                            <li className={styles.item}>
                                <Anchor
                                    href={`/docs/${locale.terms.general.filename}`}
                                    className={styles.link}
                                    target="_blank"
                                >
                                    {locale.terms.general.title}
                                </Anchor>
                            </li>
                        </ul>
                        <ul className={styles.list}>
                            {locale.terms.grants.map(grant => (
                                <li key={grant.filename} className={styles.item}>
                                    <Anchor
                                        href={`/docs/${grant.filename}`}
                                        className={styles.link}
                                        target="_blank"
                                    >
                                        {grant.title}
                                    </Anchor>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            </div>
        </footer>
    );
};

export default Footer;

